import React from "react";
import { useForm } from "react-hook-form";

import { useStore } from "../../store/deiStore";

const Form = () => {
  const {
    changeAppStage,
    submittedComment,
    changeSubmittedComment,
  } = useStore();
  const { errors, handleSubmit, register, watch } = useForm({
    defaultValues: submittedComment,
  });
  const followUp = watch("followUp");

  const onSubmit = (data) => {
    changeSubmittedComment(data);
    changeAppStage("confirm");
  };

  return (
    <form className="form__container" onSubmit={handleSubmit(onSubmit)}>
      <div className="form__two-col">
        <p>
          Please share any thoughts, suggestions, or issues relating to Diversity, Equity & Inclusion at Madwell that you would like to raise.<br/><br/>          

          
          <strong>Your responses will be treated confidentially and your email will not be retained with the final form submission unless it is provided in the box below.</strong> If you’d also like to remain anonymous, you do not need to leave your name or contact details. 
          This form will go directly—and only—to Whitney Ball (SVP, People & Culture), Jason Torrez (DEI Panel Chair) and Gabriela Benitez (DEI Panel Chair).
        </p>
        <p className="form__intro-copy">
          If you opt to provide your contact information, you can expect to
          receive a response within 48 hours. If this matter is urgent, please
          reach out to Whitney Ball via [Slack, email, phone].
        </p>
      </div>
      <div className="form__spacer" />
      <div className="form__two-col">
        <label htmlFor="name">I'm reaching out because:</label>
        <select
          name="reason"
          ref={register({
            required: true,
            validate: (value) => value !== "default",
          })}
        >
          <option default disabled value="default">
            Select reason
          </option>
          <option>
            I have an idea, suggestion, or question for the DEI panel.
          </option>
          <option>
            I’d like to get involved with an ERG/affinity group or start a new
            one.
          </option>
          <option>
            I’d like to discuss an internal personnel or manager issue.
          </option>
          <option>I’d like to discuss a client-related topic.</option>
          <option>Other (please specify)</option>
        </select>
        {errors.reason && <p className="error">This is a required field.</p>}
      </div>
      <div className="form__two-col">
        <label htmlFor="comment">Please provide details below:</label>
        <textarea name="comment" ref={register({ required: true })}></textarea>
        {errors.comment && <p className="error">This is a required field.</p>}
      </div>
      <div className="form__two-col">
        <label>
          Would you like to be contacted as a follow-up?
          <label className="form__option-container">
            <input
              name="followUp"
              type="radio"
              value="yes"
              ref={register({ required: true })}
            />
            Yes
          </label>
          <label className="form__option-container">
            <input
              name="followUp"
              type="radio"
              value="no"
              ref={register({ required: true })}
            />
            No
          </label>
        </label>
        {errors.followUp && <p className="error">This is a required field.</p>}
      </div>
      {followUp === "yes" && (
        <>
          <div className="form__one-col">
            <label htmlFor="name">What is your name?</label>
            <input type="text" name="name" ref={register({ required: true })} />
            {errors.name && <p className="error">This is a required field.</p>}
          </div>
          <div className="form__one-col form__one-col--new-line">
            <label htmlFor="contactMethod">
              If yes, how would you prefer to be contacted?
            </label>
            <select
              name="contactMethod"
              ref={register({
                required: true,
                validate: (value) => value !== "default",
                
              })}
            >
              <option default disabled value="default">
                Select contact method
              </option>
              <option>Email</option>
              <option>Phone call/video meeting</option>
              <option>Slack</option>
              <option>Other (please specify)</option>
            </select>
            {errors.contactMethod && (
              <p className="error">This is a required field.</p>
            )}
          </div>

          <div className="form__one-col form__one-col--new-line">
            <label htmlFor="contactInfo">
              Please provide your preferred contact information:
            </label>
            <input type="text" name="contactInfo" ref={register} />
          </div>
        </>
      )}
      <div className="form__two-col">
        <label>
          Outside of [DEI chair/HR], is there anyone specific at Madwell with
          whom you would like this information to be shared?{" "}
          <span className="form__optional">(optional)</span>
        </label>
        <input type="text" name="shareComment" ref={register} />
      </div>
      <div className="form__two-col form__two-col--centered">
        <input className="form__submit-button" type="submit" />
      </div>
    </form>
  );
};

export default Form;
