import React from "react";
import { Link } from "react-router-dom";
import { GoogleLogout } from "react-google-login";
import { useToasts } from "react-toast-notifications";

import { useLoginStore } from "../../store/loginStore";

const LoginStatus = () => {
  const { loginName, loggedIn, logUserOut } = useLoginStore();
  const clientId = process.env.REACT_APP_LOGIN_CLIENT_ID;
  const { addToast } = useToasts();

  const logout = () => {
    logUserOut();
    addToast(`Successfully logged out.`, {
      appearance: "success",
    });
  };

  if (!loggedIn) {
    return (
      <div className="header__login-container">
        <Link to="/login">Login</Link>
      </div>
    );
  } else {
    return (
      <div className="header__login-container">
        <p>
          Hello, {loginName}!{" "}
          <GoogleLogout
            clientId={clientId}
            render={() => (
              <a href="#!" onClick={logout}>
                Logout
              </a>
            )}
            buttonText="Logout"
            onLogoutSuccess={logout}
          ></GoogleLogout>
        </p>
      </div>
    );
  }
};

export default LoginStatus;
