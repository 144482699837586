import React, { useEffect, useState } from "react";
import LoginButton from "./LoginButton";

const LoginScreen = ({ setLoggedOutMessage }) => {
  const [error, setError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  useEffect(() => {
    if (error) {
      setErrorMsg(error);
    }
    return () => {
      setErrorMsg("");
    };
  }, [error]);

  return (
    <main className="main-container">
      <div className="login-container">
        <p>Please log in with you Madwell Gmail account.</p>
        {error && (
          <>
            <p className="error">
              <strong>ERROR:</strong> {errorMsg}
            </p>
            <p className="error">
              If you need assistance, please contact Mike Cargian.
            </p>
          </>
        )}
        <LoginButton
          setError={setError}
          setLoggedOutMessage={setLoggedOutMessage}
        />
      </div>
    </main>
  );
};

export default LoginScreen;
