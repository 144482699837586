import create from "zustand";

const defaultFormOptions = {
  reason: "default",
  contactMethod: "default",
};

export const useStore = create((set) => ({
  // appStages: form, confirm, success
  appStage: "form",
  changeAppStage: (stage) => {
    set({ appStage: stage });
  },
  submittedComment: defaultFormOptions,
  changeSubmittedComment: (obj) => {
    set({ submittedComment: obj });
  },
}));
