import React from "react";

const SuccessScreen = () => {
  return (
    <div className="success__container">
      <h2 className="success__header">Thank you for your submission!</h2>
      <p>
        If you opted to be contacted, you should receive a response within 48
        hours.
      </p>
    </div>
  );
};

export default SuccessScreen;
