import http from "../http-common";

class OnboardingDataService {
	getAll(token) {
		return http.get("/onboarding", {
			headers: {
				"Content-type": "application/json",
				"Authorization": `Bearer ${token}`
			}
		});
	}

	get(id, token) {
		return http.get(`/onboarding/${id}`, {
			headers: {
				"Content-type": "application/json",
				"Authorization": `Bearer ${token}`
			}
		});
	}

	create(data) {
		return http.post("/onboarding", data);
	}

	update(id, data) {
		return http.put(`/onboarding/${id}`, data);
	}

	delete(id) {
		return http.delete(`/onboarding/${id}`);
	}
}

export default new OnboardingDataService();