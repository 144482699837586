import React from "react";
import { Helmet } from "react-helmet-async";
import { useRouteMatch, Switch, Route } from "react-router-dom";
import "../../styles/index.scss";

import Header from "../global/Header";
import AddEmployee from "./add/AddEmployee";
import ListEmployees from "./list/ListEmployees";
import EditEmployees from "./edit/EditEmployees";

export default function OnboardingApp() {
  let { path } = useRouteMatch();

  return (
    <>
      <Helmet>
        <title>Madwell Employee Onboarding</title>
      </Helmet>
      <Header title="Madwell Onboarding" />
      <Switch>
        <Route exact path={path}>
          <AddEmployee />
        </Route>
        <Route path={`${path}/list`}>
          <ListEmployees />
        </Route>
        <Route path={`${path}/edit/:id`}>
          <EditEmployees />
        </Route>
      </Switch>
    </>
  );
}
