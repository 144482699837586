import React, { useState } from "react";
import { Link } from "react-router-dom";

import { useStore } from "../../../store/offboardingStore";
import { useLoginStore } from "../../../store/loginStore";

import LoginScreen from "../../global/LoginScreen";
import Form from "../shared/Form";
import ConfirmScreen from "./ConfirmScreen";
import SuccessScreen from "./SuccessScreen";

const UnboardEmployee = () => {
  const { appStage } = useStore();
  const { loggedIn, adminLogin } = useLoginStore();
  const [loggedOutMessage, setLoggedOutMessage] = useState(false);

  const renderSwitch = (stage) => {
    if (!loggedIn) {
      return <LoginScreen setLoggedOutMessage={setLoggedOutMessage} />;
    }
    switch (stage) {
      case "form":
        return <Form />;
      case "confirm":
        return <ConfirmScreen />;
      case "success":
        return <SuccessScreen />;
      default:
        return null;
    }
  };

  return (
    <main className="main-container">
      {loggedOutMessage && (
        <p>
          <strong>Successfully logged out.</strong>
        </p>
      )}

      {adminLogin && <Link to="/onboarding/list">Edit/Delete Onboarding Entries</Link>}
      {renderSwitch(appStage)}
    </main>
  );
};

export default UnboardEmployee;
