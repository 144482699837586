import { useEffect, useState } from "react";
import axios from "axios";

import { useLoginStore } from "../store/loginStore"
import { useStore } from "../store/quoteStore";

const useCategories = () => {
  const { categories, changeCategories } = useStore();
  const { wpToken } = useLoginStore();
  const [parentCats, setParentCats] = useState([]);

  useEffect(() => {
    const catEndpoint = `${process.env.REACT_APP_QUOTES_API_URL}/wp-json/wp/v2/categories?per_page=100`;
    if (categories.length < 1 || parentCats.length < 1) {
      axios.get(catEndpoint, {
          headers: {
            "Content-type": "application/json",
            "Authorization": `Bearer ${wpToken}`
          }
      }).then((res) => {
        console.log(res)
        changeCategories(res.data);
        const parentCatsArr = res.data.filter(
          (cat) => cat.parent === 0 && cat.slug !== "uncategorized"
        );
        setParentCats(parentCatsArr);
      });
    }
  }, [categories.length, changeCategories, parentCats, wpToken]);

  // function for returning name of category or subcategory
  const categoryLookup = (id) => {
    const catObj = categories.find((cat) => cat.id === id);
    if (catObj.name) {
      return catObj.name;
    }
  };

  // function for looking up name of parent category
  const parentCategoryLookup = (id) => {
    const catObj = categories.find((cat) => cat.id === id);
    if (catObj.parent && catObj.parent !== 0) {
      return categoryLookup(catObj.parent);
    }
  };

  const obj = {
    parentCategories: parentCats,
    categories: categories,
    categoryLookup: categoryLookup,
    parentCategoryLookup: parentCategoryLookup,
  };

  return obj;
};

export { useCategories };
