import React from "react";
import { Helmet } from "react-helmet-async";
import { useRouteMatch, Switch, Route } from "react-router-dom";

import Header from "../global/Header";
import Form from "./Form";
import ExcerptsScreen from "./ExcerptsScreen";

const QuotesApp = () => {
  let { path } = useRouteMatch();

  return (
    <>
      <Helmet>
        <title>Madwell Employee Onboarding</title>
      </Helmet>
      <Header title="Madwell Quote Generator" />
      <Switch>
        <Route exact path={path}>
          <Form />
        </Route>
        <Route path={`${path}/generated/`}>
          <ExcerptsScreen />
        </Route>
      </Switch>
    </>
  );
};

export default QuotesApp;
