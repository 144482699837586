import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

const DeleteButton = ({ onClick }) => {
  return (
    <Button onClick={onClick}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="18"
        height="18"
        viewBox="0 0 24 24"
      >
        <path d="M3 6v18h18v-18h-18zm5 14c0 .552-.448 1-1 1s-1-.448-1-1v-10c0-.552.448-1 1-1s1 .448 1 1v10zm5 0c0 .552-.448 1-1 1s-1-.448-1-1v-10c0-.552.448-1 1-1s1 .448 1 1v10zm5 0c0 .552-.448 1-1 1s-1-.448-1-1v-10c0-.552.448-1 1-1s1 .448 1 1v10zm4-18v2h-20v-2h5.711c.9 0 1.631-1.099 1.631-2h5.315c0 .901.73 2 1.631 2h5.712z" />
      </svg>
    </Button>
  );
};

const EditButton = ({ id }) => {
  return (
    <CenteredLink to={`./edit/${id}`}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="18"
        height="18"
        viewBox="0 0 24 24"
      >
        <path d="M18.363 8.464l1.433 1.431-12.67 12.669-7.125 1.436 1.439-7.127 12.665-12.668 1.431 1.431-12.255 12.224-.726 3.584 3.584-.723 12.224-12.257zm-.056-8.464l-2.815 2.817 5.691 5.692 2.817-2.821-5.693-5.688zm-12.318 18.718l11.313-11.316-.705-.707-11.313 11.314.705.709z" />
      </svg>
    </CenteredLink>
  );
};

const EditDeleteButtons = ({ deleteFn, id }) => {
  return (
    <>
      <EditButton id={id} />
      <DeleteButton onClick={deleteFn} />
    </>
  );
};

export default EditDeleteButtons;

const Button = styled.button`
  display: inline-block;
  border: none;
  margin: 0;
  padding: 2px;
  line-height: 1;
  background: transparent;
  -webkit-appearance: none;
  svg {
    path {
      transition: 0.25s fill;
    }
  }
  &:hover,
  &:active,
  &:focus {
    background: transparent;
    svg {
      path {
        fill: var(--accent-color);
      }
    }
  }
`;

const CenteredLink = styled(Link)`
  display: flex;
  align-items: center;
`;
