import React from "react";
import { GoogleLogin } from "react-google-login";
import { useToasts } from "react-toast-notifications";

import { useLoginStore } from "../../store/loginStore";

const LoginButton = () => {
  const { logUserIn } = useLoginStore();
  const clientId = process.env.REACT_APP_LOGIN_CLIENT_ID;
  const { addToast } = useToasts();

  const onSuccess = (res) => {
    console.log("Login Success! currentUser:", res.profileObj);
    const { name, email } = res.profileObj;
    logUserIn(name, email, res.tokenObj.id_token);
    addToast(`Successfully logged in. Hi there, ${name}!`, {
      appearance: "success",
    });
  };

  const onFailure = (res) => {
    // closing the Gmail popup triggers an error, but feels like an odd
    // thing to show the end user
    if (res.error === "popup_closed_by_user") {
      return;
    }
    console.error("Login failed! res:", res);
    addToast(`Error logging in: ${res.error}.`, {
      appearance: "error",
    });
  };

  return (
    <div>
      <GoogleLogin
        clientId={clientId}
        buttonText="Login"
        onSuccess={onSuccess}
        onFailure={onFailure}
        className="login-button"
      />
    </div>
  );
};

export default LoginButton;
