import React, { useState } from "react";

import { useStore } from "../../../store/offboardingStore";
import { useLoginStore } from "../../../store/loginStore";
import { formatDate } from "../../../utils/formatters"

import EmployeeDataService from "../../../services/employee.service";
import axios from "axios";

const ConfirmScreen = () => {
  const { submittedEmployee, changeAppStage, office } = useStore();
  const { googleToken } = useLoginStore();
  const {
    firstName,
    lastName,
    workEmail,
    managerEmail,
    endDate, 
    officeId
  } = submittedEmployee;

  const [errorMsg, setErrorMsg] = useState(null);
  const [newEmployee, setNewEmployee] = useState(false);

  const goBack = () => {
    changeAppStage("form");
  };

  const onSubmit = () => {
    EmployeeDataService.getAll(googleToken).then((result) => {
      const employees = result.data;

      const match = {
        firstName: firstName,
        lastName: lastName,
        emailFormat: workEmail,
        managerEmail: managerEmail,
        officeId: officeId
      }

      const matches = employees.filter((emp) => {
        for (const key in match) {
          if (emp[key] === null || emp[key].toString() !== match[key]) {
            return false;
          }
        }
        return true;
      });

      if (matches.length > 0) {
        matches.forEach((match) => {
          match.endDate = endDate;

          axios.put(`${process.env.REACT_APP_API_URL}/api/employees/${match.id}`, {
            token: googleToken,
            data: match
          });
        });

        changeAppStage("success");
      } else {
        setErrorMsg('No employees match the provided data.');
        setNewEmployee(true);
      }
    });
  };

  const addNewEmployee = () => {
    const newEmployee = {
      firstName: firstName,
      lastName: lastName,
      endDate: endDate,
      emailFormat: workEmail,
      managerEmail: managerEmail,
      officeId: officeId
    }

    const postObj = {
      data: newEmployee,
      token: googleToken
    }

    EmployeeDataService.create(postObj)
      .then((response) => {
        console.log(response.data);
        changeAppStage("success");
      })
      .catch((err) => {
        console.log(err);
        console.log(err.response);
      });
  }
  

  const displayEndDate = endDate && formatDate(endDate);

  return (
    <div className="confirm__container">
      <h2 className="confirm__header">Does everything look correct?</h2>
      <blockquote className="confirm__blockquote">
        <strong>First Name:</strong> {firstName}
        <br />
        <strong>Last Name:</strong> {lastName}
        <br />
        <strong>Work Email:</strong> {workEmail}
        <br />
        <strong>Manager Email:</strong> {managerEmail}
        <br />
        <strong>Office:</strong> {office}
        <br />
        <strong>End Date:</strong> {displayEndDate}
      </blockquote>

      {errorMsg && (
        <div className="confirm__errors">
          <p className="error">
            <strong>ERROR:</strong> {errorMsg}
          </p>
        </div>
      )}

      <div className="confirm__button-container">
        <button onClick={goBack}>Go Back</button>

        {newEmployee ? (
          <button onClick={addNewEmployee} className="confirm__new-employee-button">
            Add New Employee Record
          </button>
        ) : 
          <button className="confirm__submit-button" onClick={onSubmit}>
            Offboard Employee
          </button> 
        }
      </div>
    </div>
  );
};

export default ConfirmScreen;
