import React from "react";
import styled from "styled-components";

const formatContent = (string) => {
  const MAX_LENGTH = 150;
  let formatted = string.replace(/<\/?[^>]+(>|$)/g, ""); // strip HTML
  if (formatted.length > MAX_LENGTH) {
    return formatted.substring(0, MAX_LENGTH).trimEnd() + "...";
  } else {
    return formatted;
  }
};

const Icon = () => {
  return (
    <svg
      width="18"
      height="18"
      xmlns="http://www.w3.org/2000/svg"
      fillRule="evenodd"
      clipRule="evenodd"
      viewBox="0 0 24 24"
    >
      <path d="M24 17.981h-13l-7 5.02v-5.02h-4v-16.981h24v16.981zm-2-14.981h-20v12.981h4v3.125l4.357-3.125h11.643v-12.981zm-9 6.001v5h-2v-5h2zm-1-1.5c.69 0 1.25-.56 1.25-1.25s-.56-1.25-1.25-1.25-1.25.56-1.25 1.25.56 1.25 1.25 1.25z" />
    </svg>
  );
};

const Tooltip = ({ content }) => {
  return (
    <Container>
      <Icon />
      <Copy>{formatContent(content)}</Copy>
    </Container>
  );
};

export default Tooltip;

const Container = styled.div`
  margin-left: 8px;
  cursor: pointer;
  position: absolute;
  right: 10px;
  bottom: 8px;

  svg {
    path {
      fill: #333333;
      transition: fill 0.2s;
    }
  }

  &:hover {
    & > div {
      opacity: 1;
    }
    svg {
      path {
        fill: var(--accent-color);
      }
    }
  }
`;

const Copy = styled.div`
  opacity: 0;
  border: 1px solid var(--accent-color);
  background: white;
  position: absolute;
  right: 30px;
  bottom: 10px;
  font-size: 14px;
  font-weight: 400;
  width: 275px;
  padding: 8px;
  transition: opacity 0.3s;
  pointer-events: none;
  z-index: 4;
`;
