import React from "react";
import { Helmet } from "react-helmet-async";
import "../../styles/index.scss";

import { useLoginStore } from "../../store/loginStore";

import Header from "../global/Header";
import LoginScreen from "../global/LoginScreen";

export default function LoginApp() {

  const CheckLogin = () => {
    const { loggedIn } = useLoginStore();
    if (!loggedIn) {
      return <LoginScreen />;
    }
  }

  return (
    <>
      <Helmet>
        <title>Madwell Employee Onboarding</title>
      </Helmet>
      <Header title="Madwell Forms Login" />
      {CheckLogin()}
    </>
  );
}