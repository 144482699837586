import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { ToastProvider } from "react-toast-notifications";

import { useLoginStore } from "./store/loginStore";

import LoginProvider from "./components/global/LoginProvider";
import DEIApp from "./components/dei/DEIApp";
import OnboardingApp from "./components/onboarding/OnboardingApp";
import UnboardingApp from "./components/offboarding/OffboardingApp";
import QuotesApp from "./components/quotes/QuotesApp";
import LoginApp from "./components/login/LoginApp";
import RootApp from "./components/RootApp";

const App = () => {
  const { loggedIn } = useLoginStore();
  return (
    <HelmetProvider>
      <LoginProvider>
        <ToastProvider autoDismiss={true} placement="top-right">
          <Router>
            <Helmet>
              <title>Madwell Forms</title>
            </Helmet>
            <Switch>
              <Route path="/onboarding">
                <OnboardingApp />
              </Route>
              <Route path="/offboarding">
                <UnboardingApp />
              </Route>
              <Route path="/dei">
                <DEIApp />
              </Route>
              <Route path="/quotes">
                <QuotesApp />
              </Route>
              <Route path="/login">
                {loggedIn ? <Redirect to="/" /> : <LoginApp />}
              </Route>
              <Route path="/">
                <RootApp />
              </Route>
            </Switch>
          </Router>
        </ToastProvider>
      </LoginProvider>
    </HelmetProvider>
  );
};

export default App;
