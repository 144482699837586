import React from "react";
import styled from "styled-components";

const DragDots = ({ isDragging }) => {
  return (
    <Dots
      isDragging={isDragging}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 13 21"
    >
      <circle cx="2.5" cy="2.5" r="2.5" />
      <circle cx="2.5" cy="10.5" r="2.5" />
      <circle cx="2.5" cy="18.5" r="2.5" />
      <circle cx="10.5" cy="18.5" r="2.5" />
      <circle cx="10.5" cy="10.5" r="2.5" />
      <circle cx="10.5" cy="2.5" r="2.5" />
    </Dots>
  );
};

export default DragDots;

const Dots = styled.svg`
  circle {
    transition: fill 0.2s;
    fill: ${(props) => (props.isDragging ? "var(--accent-color)" : "#C4C4C4")};
  }
`;
