import React from "react";
import { useStore } from "../../../store/offboardingStore";

const SuccessScreen = () => {
  const {
    submittedEmployee,
    resetSubmittedEmployee,
    changeAppStage,
    resetForm,
  } = useStore();
  const { firstName, lastName } = submittedEmployee;

  const submitAnotherEmployee = () => {
    resetForm();
    resetSubmittedEmployee();
    changeAppStage("form");
  };

  return (
    <div className="success__container">
      <h2 className="success__header">
        Successfully offboarded <br />
        <strong>
          {firstName} {lastName}
        </strong>
        .
      </h2>
      
      <button onClick={submitAnotherEmployee}>Offboard Another Employee</button>
    </div>
  );
};

export default SuccessScreen;
