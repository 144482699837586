import create from "zustand";

export const useStore = create((set) => ({
  categories: [],
  changeCategories: (arr) => {
    set({ categories: arr });
  },
  pages: [],
  changePages: (arr) => {
    set({ pages: arr });
  },
  quotePages: [],
  changeQuotePages: (arr) => {
    set({ quotePages: arr });
  },
}));
