import create from "zustand";

const defaultFormOptions = {
  departmentId: 'default',
  officeId: 'default'
}

const defaultOnboarding = {
  itOnboard: false,
  financeOnboard: false,
  officeOnboard: false
}

export const useStore = create((set) => ({
  // appStages: form, confirm, success
  appStage: "form",
  changeAppStage: (stage) => {
    set({ appStage: stage });
  },
  submittedEmployee: defaultFormOptions,
  changeSubmittedEmployee: (obj) => {
    set({ submittedEmployee: obj })
  },
  resetSubmittedEmployee: () => {
    set({ submittedEmployee: defaultFormOptions })
  },
  changeOnboarding: (obj) => {

  },
  office: null,
  changeOffice: (office) => {
    set({ office: office })
  },
  department: null,
  changeDepartment: (dept) => {
    set({ department: dept })
  },
  resetForm: null,
  changeResetForm: (func) => {
    set({ resetForm: func })
  },
  departmentValues: [],
  changeDepartmentValues: (arr) => {
    set({departmentValues: arr})
  },
  officeValues: [],
  changeOfficeValues: (arr) => {
    set({officeValues: arr})
  },
  softwareValues: [],
  changeSoftwareValues: (arr) => {
    set({softwareValues: arr})
  }
}));
