import React, { useEffect, useState } from "react";
import axios from "axios";

import { useLoginStore } from "../../store/loginStore";
import { useStore } from "../../store/quoteStore";
import { useCategories } from "../../hooks/useCategories";

const SectionChooser = () => {
  const { wpToken } = useLoginStore();
  const { parentCategories, categories } = useCategories();
  const { quotePages, changeQuotePages } = useStore();
  const [selectedCategory, setSelectedCategory] = useState("default");
  const [selectedSubcategory, setSelectedSubcategory] = useState("default");
  const [subcategories, setSubcategories] = useState([]);

  // stores pages from API request
  const [categoryPages, setCategoryPages] = useState([]);
  // array of pages displayed in pages <select>
  const [filteredPages, setFilteredPages] = useState([]);
  // represents selected value in pages <select>
  const [selectedPage, setSelectedPage] = useState("default");

  const categoryCopy =
    categories.length > 0 ? "Select category" : "Loading categories…";
  const subcategoriesCopy =
    subcategories.length > 0 ? "Select subcategory" : "";
  const pagesCopy = filteredPages.length > 0 ? "Select page" : "";

  // used to quickly see if page ID is already in quotePages array
  const inQuotePages = (id) => {
    const findPage = quotePages.find((page) => page.id === id);
    return findPage !== undefined;
  };

  // sets subcategory select field values
  useEffect(() => {
    if (selectedCategory !== "default") {
      const filteredSubs = categories.filter(
        (cat) => cat.parent === parseInt(selectedCategory)
      );
      setSubcategories(filteredSubs);

      // creates a string of comma-separated subcategory IDs for our API request
      const filteredSubsIds = filteredSubs.map((sub) => sub.id);
      const catIdArr = [...filteredSubsIds, selectedCategory];
      const catString = catIdArr.join(",");

      const pageEndpoint = `${process.env.REACT_APP_QUOTES_API_URL}/wp-json/wp/v2/pages?categories=${catString}`;
      axios
        .get(pageEndpoint, {
          headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${wpToken}`,
          },
        })
        .then((res) => {
          setCategoryPages(res.data);
        });
    } else if (subcategories.length > 0) {
      setSubcategories([]);
    }
  }, [categories, selectedCategory, subcategories.length, wpToken]);

  // sets pages select field values
  useEffect(() => {
    if (selectedSubcategory !== "default") {
      const filteredArr = categoryPages.filter((page) =>
        page.categories.includes(parseInt(selectedSubcategory))
      );
      setFilteredPages(filteredArr);
    } else if (filteredPages.length > 0) {
      setFilteredPages([]);
    }
  }, [categoryPages, filteredPages.length, selectedSubcategory]);

  const onAddPage = () => {
    if (selectedPage === "default") {
      return;
    }
    // find selected page based on ID
    const quoteArrCopy = Array.from(quotePages);
    const pageObj = categoryPages.find(
      (page) => page.id === parseInt(selectedPage)
    );

    // if the page has already been added to Selected Pages section, it will
    // have the disabled attribute
    if (inQuotePages(parseInt(selectedPage))) {
      return;
    }

    // add the page object to quote arr
    quoteArrCopy.push(pageObj);
    changeQuotePages(quoteArrCopy);

    pageObj.disabled = true;
    setSelectedPage("default");
  };

  return (
    <div className="quotes__chooser-container">
      <label>Category</label>
      <select
        value={selectedCategory}
        onChange={(e) => {
          setSelectedCategory(e.target.value);
          setSelectedSubcategory("default");
          setSelectedPage("default");
        }}
      >
        <option value="default" disabled>
          {categoryCopy}
        </option>
        {parentCategories.map((cat) => {
          return (
            <option key={cat.id} value={cat.id}>
              {cat.name}
            </option>
          );
        })}
      </select>
      <label>Subcategory</label>
      <select
        value={selectedSubcategory}
        onChange={(e) => {
          setSelectedSubcategory(e.target.value);
          setSelectedPage("default");
        }}
      >
        <option value="default" disabled>
          {subcategoriesCopy}
        </option>
        {subcategories.map((cat) => {
          return (
            <option key={cat.id} value={cat.id}>
              {cat.name}
            </option>
          );
        })}
      </select>
      <label>Page</label>
      <select
        value={selectedPage}
        onChange={(e) => {
          setSelectedPage(e.target.value);
        }}
      >
        <option value="default" disabled>
          {pagesCopy}
        </option>
        {filteredPages.map((page) => {
          return (
            <option
              key={page.id}
              value={page.id}
              disabled={inQuotePages(page.id)}
            >
              {page.title.rendered}
            </option>
          );
        })}
      </select>
      <button className="quotes__add-page-button" onClick={onAddPage}>
        Add page
      </button>
    </div>
  );
};

export default SectionChooser;
