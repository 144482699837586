import React, { useState } from "react";
import { Helmet } from "react-helmet-async";

import Header from "../global/Header";
import LoginScreen from "../global/LoginScreen";
import Form from "./Form";
import ConfirmScreen from "./ConfirmScreen";
import SuccessScreen from "./SuccessScreen";

import { useStore } from "../../store/deiStore";
import { useLoginStore } from "../../store/loginStore";

const DEIApp = () => {
  const { appStage } = useStore();
  const { loggedIn } = useLoginStore();
  const [loggedOutMessage, setLoggedOutMessage] = useState(false);

  const renderSwitch = (stage) => {
    // if (!loggedIn) {
    //   return <LoginScreen setLoggedOutMessage={setLoggedOutMessage} />;
    // }
    switch (stage) {
      case "form":
        return <Form />;
      case "confirm":
        return <ConfirmScreen />;
      case "success":
        return <SuccessScreen />;
      default:
        return null;
    }
  };

  return (
    <>
      <Helmet>
        <title>Madwell Diversity, Equity & Inclusion Support Line</title>
      </Helmet>
      <Header title="Diversity, Equity & Inclusion Support Line" />
      <main className="main-container">
        {loggedOutMessage && (
          <p>
            <strong>Successfully logged out.</strong>
          </p>
        )}
        {renderSwitch(appStage)}
      </main>
    </>
  );
};

export default DEIApp;
