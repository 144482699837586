import React from "react";
import { useParams } from "react-router-dom";

import { useLoginStore } from "../../../store/loginStore";

import LoginScreen from "../../global/LoginScreen";
import Form from "../shared/Form";

const EditEmployees = () => {
  const { loggedIn, adminLogin } = useLoginStore();
  const { id } = useParams();
  return (
    <main className="main-container">
      {!loggedIn ? (
        <LoginScreen />
      ) : !adminLogin ? (
        <p className="error">
          Sorry, you do not have permission to access this page.
        </p>
      ) : (
        <>
        <h2>Editing Entry</h2>
        <Form editId={id} />
        </>
      )}
    </main>
  );
};

export default EditEmployees;
