import React from "react";
import styled from "styled-components";

const ArrowIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      viewBox="0 0 24 24"
    >
      <path d="M0 16.67l2.829 2.83 9.175-9.339 9.167 9.339 2.829-2.83-11.996-12.17z" />
    </svg>
  );
};

const MoveArrows = ({ upFn, downFn }) => {
  return (
    <Container>
      <ArrowButton onClick={upFn}>
        <ArrowIcon />
      </ArrowButton>
      <ArrowButton onClick={downFn}>
        <ArrowIcon />
      </ArrowButton>
    </Container>
  );
};

export default MoveArrows;

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column nowrap;
  padding-bottom: 10px;

  & > button:nth-child(2) {
    transform: rotate(180deg);
  }
`;

const ArrowButton = styled.button`
  display: inline-block;
  border: none;
  margin: 0;
  padding: 2px 10px;
  line-height: 1;
  background: transparent;
  -webkit-appearance: none;

  svg {
    path {
      transition: 0.2s fill;
      fill: #999999;
    }
  }

  &:hover,
  &:active,
  &:focus {
    background: transparent;

    svg {
      path {
        fill: var(--accent-color);
      }
    }
  }
`;
