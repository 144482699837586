import React, { useState } from "react";
import axios from "axios";

import { useStore } from "../../store/deiStore";

const toTitleCase = (str) => {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
};

const ConfirmScreen = () => {
  const { submittedComment, changeAppStage } = useStore();
  const {
    comment,
    followUp,
    reason,
    shareComment,
    name,
    contactMethod,
    contactInfo,
  } = submittedComment;
  const [errorMsg, setErrorMsg] = useState(null);

  const goBack = () => {
    changeAppStage("form");
  };

  const onSubmit = () => {
    axios
      .post(`${process.env.REACT_APP_API_URL}/dei`, submittedComment)
      .then((res) => {
        console.log(res);
        changeAppStage("success");
      })
      .catch((err) => {
        console.log(err);
        const displayError =
          err.response.data && err.response.data.message
            ? err.response.data.message
            : err;
        setErrorMsg(displayError);
      });
  };

  return (
    <div className="confirm__container">
      <h2 className="confirm__header">Does everything look correct?</h2>
      <blockquote className="confirm__blockquote">
        <p>
          <strong>I'm reaching out because:</strong>
          <br />
          {reason}
        </p>
        <p>
          <strong>Details:</strong>
          <br />
          {comment}
        </p>
        <p>
          <strong>Would you like to be contacted as a follow-up?</strong> <br />
          {toTitleCase(followUp)}
        </p>
        {name && (
          <p>
            <strong>Name:</strong>
            <br />
            {name}
          </p>
        )}
        {contactMethod && (
          <p>
            <strong>Preferred contact method:</strong>
            <br />
            {contactMethod}
          </p>
        )}
        {contactInfo && (
          <p>
            <strong>Contact information:</strong>
            <br />
            {contactInfo}
          </p>
        )}
        {shareComment && (
          <p>
            <strong>
              Outside of [DEI chair/HR], is there anyone specific at Madwell
              with whom you would like this information to be shared?
            </strong>
            <br />
            {shareComment}
          </p>
        )}
      </blockquote>
      {errorMsg && (
        <p className="error">
          <strong>ERROR:</strong> {errorMsg}
        </p>
      )}
      <div className="confirm__button-container">
        <button onClick={goBack}>Go Back</button>
        <button className="confirm__submit-button" onClick={onSubmit}>
          Submit
        </button>
      </div>
    </div>
  );
};

export default ConfirmScreen;
