import React from "react";
import { useStore } from "../../../store/onboardingStore";

const SuccessScreen = () => {
  const {
    submittedEmployee,
    resetSubmittedEmployee,
    changeAppStage,
    resetForm,
  } = useStore();
  const { firstName, lastName, preferredName } = submittedEmployee;
  const displayName = preferredName ? preferredName : firstName;

  const submitAnotherEmployee = () => {
    resetForm();
    resetSubmittedEmployee();
    changeAppStage("form");
  };

  return (
    <div className="success__container">
      <h2 className="success__header">
        Successfully submitted onboarding for <br />
        <strong>
          {displayName} {lastName}
        </strong>
        .
      </h2>
      <button onClick={submitAnotherEmployee}>Submit Another Employee</button>
    </div>
  );
};

export default SuccessScreen;
