import { format, parseISO } from "date-fns";

export const toTitleCase = (str) => {
  if (!str) return null;
  if (str === "pc") {
    // makes sure it displays as "PC" and not "Pc"
    return "PC";
  }
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
};

export const formatDate = (date) => {
  const formattedDate =
    (date.getMonth() > 8 ? date.getMonth() + 1 : "0" + (date.getMonth() + 1)) +
    "/" +
    (date.getDate() > 9 ? date.getDate() : "0" + date.getDate()) +
    "/" +
    date.getFullYear();
  return formattedDate;
};

export const formatDateFromDB = (date) => {
  if (date) {
    return format(parseISO(date), "MMM d, uu");
  }
  return null;
};

export const formatEmploymentStatus = (stat) => {
  if (stat === "fulltime") {
    return "Full-time";
  } else if (stat === "freelance") {
    return "Freelance";
  }
  return stat;
};

export const formatPhoneNumber = (num) => {
  const cleaned = ("" + num).replace(/\D/g, "");
  const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    const intlCode = match[1] ? "+1 " : "";
    return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join("");
  }
  return null;
};

export const formatDisplayName = (first, last, preferred) => {
  if (preferred) {
    return preferred + " " + last;
  } else {
    return first + " " + last;
  }
};
