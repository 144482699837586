import React, { useState } from "react";

import { useStore } from "../../../store/onboardingStore";
import { useLoginStore } from "../../../store/loginStore";
import { useSelectValues } from "../../../hooks/useSelectValues";
import { toTitleCase, formatDate, formatPhoneNumber } from "../../../utils/formatters"

import EmployeeDataService from "../../../services/employee.service";

const ConfirmScreen = () => {
  const { submittedEmployee, office, department, changeAppStage } = useStore();
  const { loginEmail, loginName, googleToken } = useLoginStore();
  const {
    firstName,
    lastName,
    managerName,
    managerEmail,
    preferredName,
    title,
    employmentStatus,
    signedAcceptance,
    computer,
    homeAddress,
    shippingAddress,
    startDate,
    endDate,
    slackChannels,
    software,
    comments,
    phoneNumber,
    sharedDrives,
  } = submittedEmployee;
  const { softwares } = useSelectValues();

  const [errorMsg, setErrorMsg] = useState(null);

  // if there's a software array, make a comma-separated string of the
  // software names to show the user
  const formatSoftware = (arr) => {
    if (arr.length < 1) {
      return "None";
    }
    let str = "";
    arr.forEach((id, i) => {
      const name = softwares.find((software) => software.id === parseInt(id))
        .name;
      if (i + 1 !== arr.length) {
        str += `${name}, `;
      } else {
        str += name;
      }
    });
    return str;
  };

  const goBack = () => {
    changeAppStage("form");
  };

  const onSubmit = () => {
    console.log(submittedEmployee);
    const postObj = {
      data: submittedEmployee,
      token: googleToken,
      loginEmail: loginEmail,
      loginName: loginName,
      office: office,
      software: software,
      department: department,
    };
    EmployeeDataService.create(postObj)
      .then((response) => {
        console.log(response.data);
        changeAppStage("success");
      })
      .catch((err) => {
        console.log(err);
        const displayError =
          err.response.data && err.response.data.message
            ? err.response.data.message
            : err;
        setErrorMsg(displayError);
      });
  };

  const displayEmploymentStatus = toTitleCase(employmentStatus);
  const displaySignedAcceptance = toTitleCase(signedAcceptance);
  const displayComputer = computer && toTitleCase(computer);
  const displayPhoneNumber = formatPhoneNumber(phoneNumber);
  const displayStartDate = formatDate(startDate);
  const displayEndDate = endDate && formatDate(endDate);
  const displayString = software && formatSoftware(software);

  return (
    <div className="confirm__container">
      <h2 className="confirm__header">Does everything look correct?</h2>
      <blockquote className="confirm__blockquote">
        <strong>First Name:</strong> {firstName}
        <br />
        <strong>Last Name:</strong> {lastName}
        <br />
        {preferredName && (
          <>
            <strong>Preferred Name: </strong> {preferredName}
            <br />
          </>
        )}
        <strong>Phone Number:</strong> {displayPhoneNumber}
        <br />
        <strong>Title:</strong> {title}
        <br />
        <strong>Department:</strong> {department}
        <br />
        <strong>Manager:</strong> {managerName}
        <br />
        <strong>Manager Email:</strong> {managerEmail}
        <br />
        <strong>Office:</strong> {office}
        <br />
        <strong>Employment Type:</strong> {displayEmploymentStatus}
        <br />
        <strong>Acceptance Letter:</strong> {displaySignedAcceptance}
        <br />
        {displayComputer && (
          <>
            <strong>Computer:</strong> {displayComputer}
            <br />
          </>
        )}
        <strong>Address:</strong> {homeAddress}
        <br />
        {shippingAddress && (
          <>
            <strong>Shipping Address:</strong> {shippingAddress}
            <br />
          </>
        )}
        <strong>Start Date:</strong> {displayStartDate}
        <br />
        {endDate && (
          <>
            <strong>Contract End Date: </strong> {displayEndDate}
            <br />
          </>
        )}
        {software && (
          <>
            <strong>Software:</strong> {displayString}
            <br />
          </>
        )}
        {slackChannels && (
          <>
            <strong>Slack Channels:</strong> {slackChannels}
            <br />
          </>
        )}
        {sharedDrives && (
          <>
            <strong>Shared Drives:</strong> {sharedDrives}
            <br />
          </>
        )}
        {comments && (
          <>
            <strong>Additional Comments:</strong> {comments}
            <br />
          </>
        )}
      </blockquote>
      {errorMsg && (
        <p className="error">
          <strong>ERROR:</strong> {errorMsg}
        </p>
      )}
      <div className="confirm__button-container">
        <button onClick={goBack}>Go Back</button>
        <button className="confirm__submit-button" onClick={onSubmit}>
          Submit Employee
        </button>
      </div>
    </div>
  );
};

export default ConfirmScreen;
