import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import sanitizeHtml from "sanitize-html";

import { useLoginStore } from "../../store/loginStore";
import { useStore } from "../../store/quoteStore";

import LoginScreen from "../global/LoginScreen";

const ExcerptsScreen = () => {
  const history = useHistory();
  const { quotePages, changeQuotePages } = useStore();

  const { loggedIn } = useLoginStore();

  if (!loggedIn) {
    return (
      <main className="main-container">
        <LoginScreen />
      </main>
    );
  }

  const goBack = () => {
    history.push("/quotes/");
  };

  const newExcerpts = () => {
    changeQuotePages([]);
    history.push("/quotes/");
  };

  return (
    <main className="main-container">
      <blockquote>
        Copy and paste the excerpts below into a Google Doc or to your word
        processor of choice. Formatting such as bold, italics, and bulleted list
        items will be preserved.
      </blockquote>
      {quotePages.length > 0 ? (
        <>
          {quotePages.map((page) => {
            const htmlObj = { __html: sanitizeHtml(page.content.rendered) };
            return <div key={page.id} dangerouslySetInnerHTML={htmlObj} />;
          })}
          <ButtonContainer>
            <button onClick={goBack}>Edit Excerpts</button>
            <button onClick={newExcerpts}>Generate New Excerpts</button>
          </ButtonContainer>
        </>
      ) : (
        <p>
          You have not added any excerpts yet.{" "}
          <Link to="/quotes/">Please visit the Quote Generator page</Link> and
          add some!
        </p>
      )}
    </main>
  );
};

export default ExcerptsScreen;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
  
  button:first-child {
    margin-right: 10px;
  }
`;
