import React, { useEffect } from "react";
import { useLoginStore } from "../../store/loginStore";
import Cookies from "js-cookie";

const LoginProvider = ({ children }) => {
  const { logUserIn } = useLoginStore();

  useEffect(() => {
    if (
      Cookies.get("loginName") &&
      Cookies.get("loginEmail") &&
      Cookies.get("googleToken") &&
      Cookies.get("wpToken")
    ) {
      const { loginName, loginEmail, googleToken, wpToken } = Cookies.get();
      logUserIn(loginName, loginEmail, googleToken, wpToken);
    }
  }, [logUserIn]);

  return <>{children}</>;
};

export default LoginProvider;
