import React, { useEffect, useState } from "react";
import axios from "axios";
import { useToasts } from "react-toast-notifications";
import { useForm, Controller } from "react-hook-form";
import { useHistory } from "react-router-dom";
import DatePicker from "react-datepicker";
import { parseISO } from "date-fns";

import EmployeeDataService from "../../../services/employee.service";
import OnboardingDataService from "../../../services/onboarding.service"
import { useStore } from "../../../store/onboardingStore";
import { useLoginStore } from "../../../store/loginStore";
import { useSelectValues } from "../../../hooks/useSelectValues";
import { formatDisplayName } from "../../../utils/formatters";

const Form = ({ editId }) => {
  const history = useHistory();
  const { addToast } = useToasts();
  const {
    changeAppStage,
    changeOnboarding,
    submittedEmployee,
    changeSubmittedEmployee,
    changeResetForm,
    changeOffice,
    changeDepartment,
  } = useStore();
  const { loginName, googleToken } = useLoginStore();
  const [originalData, setOriginalData] = useState(null);

  const defaultValues = submittedEmployee;
  const {
    register,
    handleSubmit,
    errors,
    watch,
    control,
    reset,
    setValue,
  } = useForm({
    defaultValues: defaultValues,
  });

  // used to pre-populate form if on edit screen; otherwise, not in use
  useEffect(() => {
    if (editId && originalData === null) {
      EmployeeDataService.get(editId, googleToken).then((res) => {
        setOriginalData(res.data);
        // set values that render conditional fields first
        const { employmentStatus, shippingAddress } = res.data;
        setValue("employmentStatus", employmentStatus);
        if (employmentStatus === "fulltime" && shippingAddress) {
          setValue("sameAddress", "no");
        } else if (employmentStatus === "fulltime") {
          setValue("sameAddress", "yes");
        }
        // get keys from object
        const dataKeys = Object.keys(res.data);
        const DATE_KEYS = ["startDate", "endDate", "createdAt", "updatedAt"];
        dataKeys.forEach((key) => {
          if (DATE_KEYS.includes(key)) {
            setValue(key, parseISO(res.data[key]));
          } else {
            setValue(key, res.data[key]);
          }
        });
      });

      OnboardingDataService.get(editId, googleToken).then((res) => {
        console.log(res.data);
      });
    }
  }, [originalData, editId, setValue, googleToken]);

  const {
    firstName,
    lastName,
    preferredName,
    employmentStatus,
    sameAddress,
    itOnboard,
    financeOnboard,
    officeOnboard
  } = watch([
    "firstName",
    "lastName",
    "preferredName",
    "employmentStatus",
    "sameAddress",
    "itOnboard",
    "financeOnboard",
    "officeOnboard"
  ]);
  const { departments, offices, softwares } = useSelectValues();
  const signedLetterCopy =
    employmentStatus === "freelance"
      ? "Signed Service Agreement?"
      : "Signed Job Acceptance Letter?";

  useEffect(() => {
    changeResetForm(reset);
  }, [reset, changeResetForm]);

  useEffect(() => {
    const convertToEmail = (first, last, preferred, emp) => {
      let finalString;
      const firstPart = preferred
        ? preferred.toLowerCase()
        : first.toLowerCase();
      // sanitized values strip all non-lowercase letters
      const sanitizedFirst = firstPart.replace(/[^a-z]/g, "");
      const sanitizedLast = last.toLowerCase().replace(/[^a-z]/g, "");

      if (emp === "freelance") {
        finalString = `${sanitizedFirst}${sanitizedLast}@madwell.com`;
      } else {
        const firstLetter = sanitizedFirst.charAt(0);
        finalString = `${firstLetter}${sanitizedLast}@madwell.com`;
      }
      return finalString;
    };

    if (firstName && lastName) {
      setValue(
        "emailFormat",
        convertToEmail(firstName, lastName, preferredName, employmentStatus)
      );
    }
  }, [employmentStatus, firstName, lastName, preferredName, setValue]);

  const getOffice = (id) => {
    return offices.find((office) => office.id === parseInt(id)).name;
  };

  const getDepartment = (id) => {
    return departments.find((department) => department.id === parseInt(id))
      .name;
  };

  const onSubmit = (data) => {
    // add page functionality
    if (!editId) {
      console.log(data);
      changeSubmittedEmployee(data);
      changeOffice(getOffice(data.officeId));
      changeDepartment(getDepartment(data.departmentId));
      changeAppStage("confirm");
    } else {
      // edit page functionality
      const putObj = {
        token: googleToken,
        data: data,
      };
      axios.put(
        `${process.env.REACT_APP_API_URL}/api/employees/${editId}`,
        putObj
      );
      history.push("/onboarding/list");
      const { firstName, lastName, preferredName } = data;
      const displayName = formatDisplayName(firstName, lastName, preferredName);
      addToast(`Entry for ${displayName} updated successfully.`, {
        appearance: "success",
      });
    }
  };

  return (
    <form className="form__container" onSubmit={handleSubmit(onSubmit)}>
      {editId ? (
        <>
          <div className="form__two-col">
            <p className="form__intro-copy">Onboarding specific items.</p>
          </div>

          <div className="form__one-col">
            <label htmlFor="itOnboard">IT Onboard</label>
            <input
              type="checkbox"
              name="itOnboard"
              id="itOnboard"
              ref={register}
            />
          </div>

          <div className="form__one-col">
            <label htmlFor="financeOnboard">Finance Onboard</label>
            <input
              type="checkbox"
              name="financeOnboard"
              id="financeOnboard"
              ref={register}
            />
          </div>

          <div className="form__one-col">
            <label htmlFor="officeOnboard">Office Onboard</label>
            <input
              type="checkbox"
              name="officeOnboard"
              id="officeOnboard"
              ref={register}
            />
          </div>

          <div className="form__spacer" />
        </>
      ) : 
        <>
          <div className="form__two-col">
            <p className="form__intro-copy">
              Please complete all the fields below to process a new employee
              onboarding. The form will automatically submit a request to
              support@madwell.com.
            </p>
          </div>
          <div className="form__spacer" />
        </>
      }

      <div className="form__one-col">
        <label>Submitted By:</label>
        <p>{loginName}</p>
      </div>
      <div className="form__one-col form__one-col--new-line">
        <label htmlFor="firstName">First Name</label>
        <input
          type="text"
          placeholder="First Name"
          name="firstName"
          ref={register({ required: true, maxLength: 80 })}
        />
        {errors.firstName && <p className="error">This is a required field.</p>}
      </div>
      <div className="form__one-col">
        <label htmlFor="lastName">Last Name</label>
        <input
          type="text"
          placeholder="Last Name"
          name="lastName"
          ref={register({ required: true, maxLength: 100 })}
        />
        {errors.lastName && <p className="error">This is a required field.</p>}
      </div>
      <div className="form__one-col">
        <label htmlFor="preferredName">
          Preferred Name <span className="form__optional">(optional)</span>
        </label>
        <input
          type="text"
          placeholder="Preferred Name"
          name="preferredName"
          ref={register}
        />
      </div>
      <div className="form__one-col form__one-col--new-line">
        <label htmlFor="personalEmail">Personal Email</label>
        <input
          type="text"
          placeholder="Personal Email"
          name="personalEmail"
          ref={register({ required: true, pattern: /^\S+@\S+$/i })}
        />
        {errors.personalEmail && errors.personalEmail.type === "required" && (
          <p className="error">This is a required field.</p>
        )}
        {errors.personalEmail && errors.personalEmail.type === "pattern" && (
          <p className="error">Please enter a valid email address.</p>
        )}
      </div>
      <div className="form__one-col">
        <label>Phone Number</label>
        <input
          type="text"
          placeholder="Phone Number"
          name="phoneNumber"
          ref={register({
            required: true,
            pattern: /^(\+\d{1,2}[\s.-]?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/,
          })}
        />
        {errors.phoneNumber && errors.phoneNumber.type === "required" && (
          <p className="error">This is a required field.</p>
        )}
        {errors.phoneNumber && errors.phoneNumber.type === "pattern" && (
          <p className="error">Please enter a valid phone number.</p>
        )}
      </div>
      <div className="form__two-col">
        <label htmlFor="homeAddress">Personal Address</label>
        <input
          type="text"
          placeholder="Street, City, State, Zip Code"
          name="homeAddress"
          ref={register({ required: true })}
        />
        {errors.homeAddress && (
          <p className="error">This is a required field.</p>
        )}
      </div>
      <div className="form__spacer" />
      <div className="form__one-col">
        <label htmlFor="title">Title/Position</label>
        <input
          type="text"
          placeholder="Title/Position"
          name="title"
          ref={register({ required: true })}
        />
        {errors.title && <p className="error">This is a required field.</p>}
      </div>
      <div className="form__one-col">
        <label htmlFor="departmentId">Department</label>
        <select
          className="department"
          name="departmentId"
          ref={register({
            required: true,
            validate: (value) => value !== "default",
          })}
        >
          <option default disabled value="default">
            Select department
          </option>
          {departments &&
            departments.map((department, index) => (
              <option value={department.id} key={index}>
                {department.name}
              </option>
            ))}
        </select>
        {errors.departmentId && (
          <p className="error">This is a required field.</p>
        )}
      </div>
      <div className="form__one-col">
        <label htmlFor="managerName">Manager Name</label>
        <input
          type="text"
          placeholder="Manager Name"
          name="managerName"
          ref={register({ required: true })}
        />
        {errors.managerName && (
          <p className="error">This is a required field.</p>
        )}
      </div>
      <div className="form__one-col">
        <label htmlFor="managerName">Manager Email</label>
        <input
          type="text"
          placeholder="Manager Email"
          name="managerEmail"
          ref={register({ required: true, pattern: /^\S+@\S+$/i })}
        />
        {errors.managerEmail && errors.managerEmail.type === "required" && (
          <p className="error">This is a required field.</p>
        )}
        {errors.managerEmail && errors.managerEmail.type === "pattern" && (
          <p className="error">Please enter a valid email address.</p>
        )}
      </div>
      <div className="form__one-col">
        <label htmlFor="officeId">Office Location</label>
        <select
          className="office"
          name="officeId"
          ref={register({
            required: true,
            validate: (value) => value !== "default",
          })}
        >
          <option default disabled value="default">
            Select office location
          </option>
          {offices &&
            offices.map((office, index) => (
              <option name={office.name} value={office.id} key={index}>
                {office.name}
              </option>
            ))}
        </select>
        {errors.officeId && <p className="error">This is a required field.</p>}
      </div>
      <div className="form__one-col">
        <label htmlFor="employmentStatus">Employment Status</label>
        <label className="form__option-container">
          <input
            name="employmentStatus"
            type="radio"
            value="fulltime"
            ref={register({ required: true })}
          />
          Full-time
        </label>
        <label className="form__option-container">
          <input
            name="employmentStatus"
            type="radio"
            value="freelance"
            ref={register({ required: true })}
          />
          Freelance
        </label>
        {errors.employmentStatus && (
          <p className="error">This is a required field.</p>
        )}
      </div>
      <div className="form__spacer" />
      <div className="form__two-col">
        <label htmlFor="emailFormat">Madwell Email</label>
        <input
          type="text"
          placeholder="Preferred Madwell email"
          name="emailFormat"
          ref={register({ required: true })}
        />
        {errors.emailFormat && (
          <p className="error">This is a required field.</p>
        )}
      </div>

      <div className="form__one-col">
        <label htmlFor="startDate">Start Date</label>
        <Controller
          name="startDate"
          control={control}
          defaultValue={""}
          render={({ onChange, value }) => (
            <DatePicker
              selected={value}
              onChange={(e) => {
                onChange(e);
              }}
            />
          )}
          rules={{ required: true }}
        />
        {errors.startDate && <p className="error">This is a required field.</p>}
      </div>
      <div className="form__one-col">
        <label htmlFor="signedAcceptance">{signedLetterCopy}</label>
        <label className="form__option-container">
          <input
            name="signedAcceptance"
            type="radio"
            value="yes"
            ref={register({ required: true })}
          />
          Yes
        </label>
        <label className="form__option-container">
          <input
            name="signedAcceptance"
            type="radio"
            value="no"
            ref={register({ required: true })}
          />
          No
        </label>
        {errors.signedAcceptance && (
          <p className="error">This is a required field.</p>
        )}
      </div>
      {employmentStatus === "fulltime" && (
        <>
          <div className="form__one-col">
            <label>Computer Preference</label>
            <label className="form__option-container">
              <input
                name="computer"
                type="radio"
                value="mac"
                ref={register({ required: true })}
              />
              Mac
            </label>
            <label className="form__option-container">
              <input
                name="computer"
                type="radio"
                value="pc"
                ref={register({ required: true })}
              />
              PC
            </label>
            {errors.computer && (
              <p className="error">This is a required field.</p>
            )}
          </div>
          <div className="form__two-col">
            <label>
              Is the computer shipping address the same as the personal address?
            </label>
            <label className="form__option-container">
              <input
                name="sameAddress"
                type="radio"
                value="yes"
                ref={register({ required: true })}
              />
              Yes
            </label>
            <label className="form__option-container">
              <input
                name="sameAddress"
                type="radio"
                value="no"
                ref={register({ required: true })}
              />
              No
            </label>
            {errors.computer && (
              <p className="error">This is a required field.</p>
            )}
          </div>
          {sameAddress === "no" && (
            <div className="form__two-col">
              <label htmlFor="shippingAddress">
                Address to ship computer to
              </label>
              <input
                type="text"
                placeholder="Street, City, State, Zip Code"
                name="shippingAddress"
                ref={register({ required: true })}
              />
              {errors.shippingAddress && (
                <p className="error">This is a required field.</p>
              )}
            </div>
          )}
        </>
      )}

      {employmentStatus === "freelance" && (
        <>
          <div className="form__one-col">
            <label htmlFor="endDate">Contract End Date</label>
            <Controller
              name="endDate"
              control={control}
              defaultValue={""}
              render={({ onChange, value }) => (
                <DatePicker
                  selected={value}
                  onChange={(e) => {
                    onChange(e);
                  }}
                />
              )}
              rules={{ required: true }}
            />
            {errors.endDate && (
              <p className="error">This is a required field.</p>
            )}
          </div>
          <div className="form__one-col form__one-col--new-line">
            <label>
              Software Needed{" "}
              <span className="form__optional">(freelance only)</span>
            </label>
            {softwares &&
              softwares.map((software, index) => (
                <label className="form__option-container" key={index}>
                  <input
                    type="checkbox"
                    placeholder={software.name}
                    name="software"
                    value={software.id}
                    ref={register}
                  />
                  {software.name}
                </label>
              ))}
          </div>
          <div className="form__one-col">
            <label htmlFor="managerName">
              Slack Channel Access{" "}
              <span className="form__optional">(freelance only)</span>
            </label>
            <input
              type="text"
              placeholder="Enter Slack channels"
              name="slackChannels"
              ref={register}
            />
          </div>
          <div className="form__two-col">
            <label htmlFor="sharedDrives">
              Shared Drives Access{" "}
              <span className="form__optional">(freelance only)</span>
            </label>
            <textarea
              placeholder="Enter shared drives"
              name="sharedDrives"
              ref={register}
            />
          </div>
        </>
      )}
      <div className="form__two-col">
        <label htmlFor="comments">Any additional comments?</label>
        <textarea
          placeholder="Additional Comments"
          name="comments"
          ref={register}
        />
      </div>

      <div className="form__two-col form__two-col--centered">
        <input
          className="form__submit-button"
          type="submit"
          value={editId ? "Edit Entry" : "Submit"}
        />
      </div>
    </form>
  );
};

export default Form;
