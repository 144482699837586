import React from "react";
import styled from "styled-components";

import { useCategories } from "../../hooks/useCategories";
import { useStore } from "../../store/quoteStore";

import CloseButton from "./CloseButton";
import DragDots from "./DragDots";
import MoveArrows from "./MoveArrows";
import Tooltip from "./Tooltip";

const DragItem = ({
  category,
  title,
  content,
  isDragging,
  onClose,
  provided,
  index,
}) => {
  const { categoryLookup, parentCategoryLookup } = useCategories();
  const { quotePages, changeQuotePages } = useStore();

  const moveUp = (i) => {
    if (i > 0) {
      const arrCopy = Array.from(quotePages);
      const [removed] = arrCopy.splice(i, 1);
      arrCopy.splice(i - 1, 0, removed);
      changeQuotePages(arrCopy);
    }
  };

  const moveDown = (i) => {
    if (i < quotePages.length - 1) {
      const arrCopy = Array.from(quotePages);
      const [removed] = arrCopy.splice(i, 1);
      arrCopy.splice(i + 1, 0, removed);
      changeQuotePages(arrCopy);
    }
  };

  return (
    <Container {...provided.draggableProps} ref={provided.innerRef}>
      <Item {...provided.dragHandleProps} isDragging={isDragging}>
        <DragDots isDragging={isDragging} />
        <ContentContainer>
          <Title>{title}</Title>
          <MetaInfo>
            {parentCategoryLookup(category)} <Bullet>•</Bullet>{" "}
            {categoryLookup(category)}
          </MetaInfo>
          <CloseButton onClick={onClose} />
          <Tooltip content={content} />
        </ContentContainer>
      </Item>
      <MoveArrows upFn={() => moveUp(index)} downFn={() => moveDown(index)} />
    </Container>
  );
};

export default DragItem;

const Container = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;

  & > div:nth-child(2) {
    opacity: 0;
    transition: opacity 0.25s;
  }

  &:hover {
    & > div:nth-child(2) {
      opacity: 1;
    }
  }
`;

const Item = styled.div`
  background: ${(props) => (props.isDragging ? "#FDFDFD" : "var(--gray)")};
  border-left: ${(props) =>
    props.isDragging
      ? "4px solid var(--accent-color)"
      : "4px solid transparent"};
  margin-bottom: 10px;
  width: 450px;
  min-height: 80px;
  padding: 8px 16px 8px 8px;
  border-radius: 2px;
  transition: background-color 0.2s, border-color 0.2s;
  position: relative;
  box-shadow: 1px 1px 2px 1px rgba(0, 0, 0, 0.15);
  display: grid;
  grid-template-columns: 10px 1fr;
  align-items: center;
  gap: 20px;

  &:hover {
    background: #fdfdfd;
  }
`;

const ContentContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
`;

const Title = styled.h3`
  font-weight: bold;
  margin: 0;
  font-size: 20px;
  letter-spacing: 0.1px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
`;

const MetaInfo = styled.p`
  margin: 0;
  color: #999999;
`;

const Bullet = styled.span`
  font-size: 10px;
`;
