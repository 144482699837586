import React, { useEffect, useState } from "react";
import styled from "styled-components";

import EmployeeTable from "./EmployeeTable";

const ListScreen = ({ data }) => {
  const [filteredData, setFilteredData] = useState(data);
  const [filteredState, setFilteredState] = useState("all");
  const [errorMessage, setErrorMessage] = useState(null);

  const setFilter = (cat) => {
    setFilteredState(cat);
    if (cat !== "all") {
      const employees = data.filter((emp) => emp.employmentStatus === cat);
      setFilteredData(employees);

      if (employees.length < 1) {
        setErrorMessage(`No ${cat} employees have been added yet.`);
      } else {
        setErrorMessage(null);
      }
    } else {
      setFilteredData(data);
    }
  };

  useEffect(() => {
    if (filteredData.length < 1) {
      setFilteredData(data);
    } 
  }, [data, filteredData.length]);

  return (
    <>
      <NavContainer>
        <Tab onClick={() => setFilter("all")} active={filteredState === "all"}>
          All
        </Tab>
        <Tab
          onClick={() => setFilter("fulltime")}
          active={filteredState === "fulltime"}
        >
          Full-Time
        </Tab>
        <Tab
          onClick={() => setFilter("freelance")}
          active={filteredState === "freelance"}
        >
          Freelancers
        </Tab>
      </NavContainer>
      <EmployeeTable data={filteredData} filteredState={filteredState} error={errorMessage} />
    </>
  );
};

export default ListScreen;

const NavContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 20px;

  button {
    margin: 0 2px;
  }
`;

const Tab = styled.button`
  color: ${(props) => (props.active ? "white" : "black")};
  background: ${(props) => (props.active ? "var(--accent-color)" : "#EFEFEF")};
`;
