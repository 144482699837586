import React, { useCallback, useEffect, useMemo, useState } from "react";
import axios from "axios";
import styled from "styled-components";
import { useTable, useBlockLayout, useSortBy } from "react-table";

import { useLoginStore } from "../../../store/loginStore";
import EditDeleteButtons from "./EditDeleteButtons";

import {
  toTitleCase,
  formatDateFromDB,
  formatEmploymentStatus,
  formatPhoneNumber,
} from "../../../utils/formatters";
import { useSelectValues } from "../../../hooks/useSelectValues";

const EmployeeTable = ({ data, filteredState, error }) => {
  const { departments, offices } = useSelectValues();
  const [deletedRows, setDeletedRows] = useState([]);
  const { googleToken } = useLoginStore();

  useEffect(() => {
    const deletedRowsFromData = data.filter((emp) => emp.deleted === true);
    const deletedIds = deletedRowsFromData.map((emp) => emp.id);
    setDeletedRows(deletedIds);
  }, [data]);

  const formatOffice = useCallback(
    (id) => {
      const result = offices.find((office) => office.id === parseInt(id));
      if (result && result.name) {
        return result.name;
      }
      return id;
    },
    [offices]
  );

  const formatDepartment = useCallback(
    (id) => {
      const result = departments.find(
        (department) => department.id === parseInt(id)
      );
      if (result && result.name) {
        return result.name;
      }
      return id;
    },
    [departments]
  );

  const columns = useMemo(
    () => [
      {
        Header: "Created On",
        accessor: "createdAt",
        Cell: ({ value }) => formatDateFromDB(value),
      },
      {
        Header: "Start Date",
        accessor: "startDate",
        Cell: ({ value }) => formatDateFromDB(value),
      },
      {

        Header: "End Date",
        accessor: "endDate",
        Cell: ({ value }) => formatDateFromDB(value),
      },
      {
        Header: "First Name",
        accessor: "firstName",
      },
      {
        Header: "Last Name",
        accessor: "lastName",
      },
      {
        Header: "Preferred Name",
        accessor: "preferredName",
        width: 190,
      },
      {
        Header: "Title",
        accessor: "title",
        width: 220,
      },
      {
        Header: "Department",
        accessor: "departmentId",
        Cell: ({ value }) => formatDepartment(value),
      },
      {
        Header: "Office",
        accessor: "officeId",
        Cell: ({ value }) => formatOffice(value),
      },
      {
        Header: "Manager",
        accessor: "managerName",
      },
      {
        Header: "Manager Email",
        accessor: "managerEmail"
      },
      {
        Header: "Employment Status",
        accessor: "employmentStatus",
        Cell: ({ value }) => formatEmploymentStatus(value),
      },
      {
        Header: "Personal Email",
        accessor: "personalEmail",
        width: 300,
      },
      {
        Header: "Madwell Email",
        accessor: "emailFormat",
        width: 300,
      },
      {
        Header: "Home Address",
        accessor: "homeAddress",
        width: 300,
      },
      {
        Header: "Shipping Address",
        accessor: "shippingAddress",
        width: 300,
      },
      {
        Header: "Phone",
        accessor: "phoneNumber",
        Cell: ({ value }) => formatPhoneNumber(value),
      },
      {
        Header: "Signed Acceptance?",
        accessor: "signedAcceptance",
        Cell: ({ value }) => toTitleCase(value),
      },
      {
        Header: "Computer",
        accessor: "computer",
        Cell: ({ value }) => toTitleCase(value),
      },
      {
        Header: "Slack Channels",
        accessor: "slackChannels",
      },
      {
        Header: "Additional Comments",
        accessor: "comments",
      },
      {
        accessor: "deleted",
      },
    ],
    [formatDepartment, formatOffice]
  );

  const deleteFn = (id) => {
    const deleted = deletedRows.includes(id);
    const putObj = {
      token: googleToken,
      data: {
        deleted: !deleted,
      },
    };
    axios.put(`${process.env.REACT_APP_API_URL}/api/employees/${id}`, putObj);
    // make copy of deletedRows state; if id was originally not in array,
    // add it. Otherwise, remove it.
    let newArr = [...deletedRows];
    if (!deleted) {
      newArr.push(id);
    } else {
      newArr = deletedRows.filter((x) => x !== id);
    }
    setDeletedRows(newArr);
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    setHiddenColumns,
  } = useTable({ columns, data }, useSortBy, useBlockLayout);

  // used to hide columns depending on which filter is selected
  useEffect(() => {
    if (filteredState === "freelance") {
      setHiddenColumns(["deleted"]);
    }
  }, [filteredState, setHiddenColumns]);

  if (data.length > 0) {
    return (
      <TableContainer>
        <Table {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                <th style={{ width: "70px" }}>Edit</th>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                    {column.render("Header")}
                    <span>
                      {column.isSorted
                        ? column.isSortedDesc
                          ? " 🔽"
                          : " 🔼"
                        : ""}
                    </span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {error ?
              <p className="error">{error}</p>
            : 
              rows.map((row) => {
                prepareRow(row);
                const { id } = row.original;
                return (
                  <Row {...row.getRowProps()} deleted={deletedRows.includes(id)}>
                    <EditCell>
                      <EditDeleteButtons
                        id={id}
                        deleteFn={() => deleteFn(id)}
                      />
                    </EditCell>
                    {row.cells.map((cell) => {
                      return (
                        <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                      );
                    })}
                  </Row>
                );
              })
            } 
          </tbody>
        </Table>
      </TableContainer>
    );
  } else {
    return <h2>Waiting…</h2>;
  }
};

export default EmployeeTable;

const TableContainer = styled.div`
  overflow-x: scroll;
  max-width: 100%;
`;

const Table = styled.table`
  thead {
    tr {
      display: flex;
      align-items: flex-end;
      height: 100%;
    }
  }
  th {
    display: flex;
    align-items: center;
  }
`;

const Row = styled.tr`
  color: ${(props) => (props.deleted ? "#AAAAAA" : "black")};
  text-decoration: ${(props) => (props.deleted ? "line-through" : "none")};
`;

const EditCell = styled.td`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-around;
  width: 70px;
`;
