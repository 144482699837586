import create from "zustand";
import Cookies from "js-cookie";
import axios from "axios";
import FormData from "form-data";

import { ADMIN_EMAILS } from "../utils/constants";

const getWordPressToken = async () => {
  const data = new FormData();
  data.append("username", process.env.REACT_APP_FETCH_USER);
  data.append("password", process.env.REACT_APP_FETCH_PASS);

  const getWpToken = {
    method: "post",
    url: `${process.env.REACT_APP_QUOTES_API_URL}/wp-json/jwt-auth/v1/token`,
    data: data,
  };

  const tokenReq = async () => {
    try {
      const res = axios(getWpToken);
      return res;
    } catch (err) {
      console.log(err.response.data.error);
    }
  };
  const tokenRes = await tokenReq();
  const { token } = await tokenRes.data.data;
  return token;
};

export const useLoginStore = create((set) => ({
  loggedIn: false,
  loginName: "",
  loginEmail: "",
  googleToken: "",
  wpToken: "",
  adminLogin: false,
  logUserIn: async (loginName, loginEmail, googleToken, wpToken) => {
    const adminLogin = ADMIN_EMAILS.includes(loginEmail);
    const COOKIE_CONFIG = { expires: 30 };
    set({
      loggedIn: true,
      loginName: loginName,
      loginEmail: loginEmail,
      googleToken: googleToken,
      wpToken: wpToken,
      adminLogin: adminLogin,
    });
    Cookies.set("loginName", loginName, COOKIE_CONFIG);
    Cookies.set("loginEmail", loginEmail, COOKIE_CONFIG);
    Cookies.set("adminLogin", adminLogin, COOKIE_CONFIG);
    Cookies.set("googleToken", googleToken, COOKIE_CONFIG);

    if (!wpToken) {
      const wpToken = await getWordPressToken();
      set({
        wpToken: wpToken,
      });
      Cookies.set("wpToken", wpToken, COOKIE_CONFIG);
    }
  },
  logUserOut: () => {
    set({
      loggedIn: false,
      loginName: "",
      loginEmail: "",
      googleToken: "",
      adminLogin: false,
    });
    Cookies.remove("loginName");
    Cookies.remove("loginEmail");
    Cookies.remove("adminLogin");
    Cookies.remove("googleToken");
    Cookies.remove("wpToken");
  },
}));
