import React from "react";
import { Link } from "react-router-dom";

import Header from "../components/global/Header";

const RootApp = () => {
  return (
    <div className="landing__container">
      <Header title="Madwell Forms" />
      <ul className="form__selection">
        <li className="form__selection--item">
          <Link to="/dei">
            <h2>DEI</h2>
            <p>Form to anonymously submit an email to the DEI panel</p>
          </Link>
        </li>
        <li className="form__selection--item">
          <Link to="/onboarding">
            <h2>Onboarding</h2>
            <p>Form to onboard a new fulltime or freelance employee</p>
          </Link>
        </li>
        <li className="form__selection--item">
          <Link to="/offboarding">
            <h2>Offboarding</h2>
            <p>Form to offboard an employee who is leaving the company</p>
          </Link>
        </li>
      </ul>
    </div>
  );
};

export default RootApp;
