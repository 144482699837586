import { useEffect } from "react";

import { useStore } from "../store/onboardingStore";

import DepartmentDataService from "../services/department.service";
import OfficeDataService from "../services/office.service";
import SoftwareDataService from "../services/software.service";

const useSelectValues = () => {
  const {
    departmentValues,
    changeDepartmentValues,
    officeValues,
    changeOfficeValues,
    softwareValues,
    changeSoftwareValues,
  } = useStore();

  const retrieveValues = (service, changeFn) => {
    service
      .getAll()
      .then((res) => {
        changeFn(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (departmentValues.length < 1) {
      retrieveValues(DepartmentDataService, changeDepartmentValues);
    }
  }, [changeDepartmentValues, departmentValues.length]);

  useEffect(() => {
    if (officeValues.length < 1) {
      retrieveValues(OfficeDataService, changeOfficeValues);
    }
  }, [changeOfficeValues, officeValues.length]);

  useEffect(() => {
    if (softwareValues.length < 1) {
      retrieveValues(SoftwareDataService, changeSoftwareValues);
    }
  }, [changeSoftwareValues, softwareValues.length]);

  const departmentLookup = (id) => {
    const deptObj = departmentValues.find((dept) => dept.id === id);
    if (deptObj && deptObj.name) {
      return deptObj.name;
    }
    return null;
  };

  const officeLookup = (id) => {
    const officeObj = officeValues.find((office) => office.id === id);
    if (officeObj && officeObj.name) {
      return officeObj.name;
    }
    return null;
  };

  const softwareLookup = (id) => {
    const softwareObj = softwareValues.find((software) => software.id === id);
    if (softwareObj && softwareObj.name) {
      return softwareObj.name;
    }
    return id;
  };

  return {
    departments: departmentValues,
    departmentLookup: departmentLookup,
    offices: officeValues,
    officeLookup: officeLookup,
    softwares: softwareValues,
    softwareLookup: softwareLookup,
  };
};

export { useSelectValues };
