import React, { useEffect, useState } from "react";
import styled from "styled-components";

import EmployeeDataService from "../../../services/employee.service";
import { useLoginStore } from "../../../store/loginStore";

import LoginScreen from "../../global/LoginScreen";
import ListScreen from "./ListScreen";

const ListEmployees = () => {
  const { loggedIn, googleToken, adminLogin } = useLoginStore();
  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [list, setList] = useState([]);

  useEffect(() => {
    if (list.length < 1 && !hasError && googleToken) {
      EmployeeDataService.getAll(googleToken)
        .then((res) => {
          console.log(res);
          setIsLoading(false);
          setList(res.data);
        })
        .catch((err) => {
          console.log(err);
          setIsLoading(false);
          setHasError(true);
          setErrorMsg(err.message);
        });
    }
  }, [googleToken, hasError, list.length]);

  return (
    <Container loggedIn={loggedIn}>
      {!loggedIn ? (
        <LoginScreen />
      ) : !adminLogin ? (
        <p className="error">
          Error: You do not have permission to access this page.
        </p>
      ) : isLoading ? (
        <h3>Loading the data…</h3>
      ) : hasError ? (
        <p className="error">Error loading the data: {errorMsg}</p>
      ) : (
        <ListScreen data={list} />
      )}
    </Container>
  );
};

export default ListEmployees;

const Container = styled.main`
  width: 100%;
  max-width: ${(props) => (props.loggedIn ? "calc(100vw - 50px)" : "800px")};
  margin: 50px auto;
  box-sizing: border-box;
  padding: 20px 60px;
  display: flex;
  justify-self: center;
  align-items: center;
  flex-flow: column nowrap;
  background: white;
  border-radius: 10px;
  box-shadow: 2px 2px 8px 2px rgba(0, 0, 0, 0.05);
`;
