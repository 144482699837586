import React, { useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import DatePicker from "react-datepicker";

import { useStore } from "../../../store/offboardingStore";
import { useLoginStore } from "../../../store/loginStore";
import { useSelectValues } from "../../../hooks/useSelectValues";

const Form = () => {
  const {
    changeAppStage,
    submittedEmployee,
    changeSubmittedEmployee,
    changeResetForm,
    changeOffice
  } = useStore();
  const { loginName } = useLoginStore();

  const defaultValues = submittedEmployee;
  const {
    register,
    handleSubmit,
    errors,
    control,
    reset,
  } = useForm({
    defaultValues: defaultValues,
  });

  const { offices } = useSelectValues();

  useEffect(() => {
    changeResetForm(reset);
  }, [reset, changeResetForm]);

  const getOffice = (id) => {
    return offices.find((office) => office.id === parseInt(id)).name;
  };

  const onSubmit = (data) => {
    console.log(data);
    changeSubmittedEmployee(data);
    changeOffice(getOffice(data.officeId));
    changeAppStage("confirm");
  };

  return (
    <form className="form__container" onSubmit={handleSubmit(onSubmit)}>
      <div className="form__two-col">
        <p className="form__intro-copy">Please complete all the fields below to look up the employee you are looking to offboard.</p>
      </div>
      
      <div className="form__spacer" />

      <div className="form__one-col">
        <label>Submitted By:</label>
        <p>{loginName}</p>
      </div>

      <div className="form__one-col form__one-col--new-line">
        <label htmlFor="firstName">First Name</label>
        <input
          type="text"
          placeholder="First Name"
          name="firstName"
          ref={register({ required: true, maxLength: 80 })}
        />
        {errors.firstName && <p className="error">This is a required field.</p>}
      </div>

      <div className="form__one-col">
        <label htmlFor="lastName">Last Name</label>
        <input
          type="text"
          placeholder="Last Name"
          name="lastName"
          ref={register({ required: true, maxLength: 100 })}
        />
        {errors.lastName && <p className="error">This is a required field.</p>}
      </div>

      
      <div className="form__one-col form__one-col--new-line">
        <label htmlFor="workEmail">Work Email</label>
        <input
          type="text"
          placeholder="Work Email"
          name="workEmail"
          ref={register({ required: true, pattern: /^\S+@\S+$/i })}
        />
        {errors.workEmail && errors.workEmail.type === "required" && (
          <p className="error">This is a required field.</p>
        )}
        {errors.workEmail && errors.workEmail.type === "pattern" && (
          <p className="error">Please enter a valid email address.</p>
        )}
      </div>

      <div className="form__one-col">
        <label htmlFor="managerName">Manager Email</label>
        <input
          type="text"
          placeholder="Manager Email"
          name="managerEmail"
          ref={register({ required: true, pattern: /^\S+@\S+$/i })}
        />
        {errors.managerEmail && errors.managerEmail.type === "required" && (
          <p className="error">This is a required field.</p>
        )}
        {errors.managerEmail && errors.managerEmail.type === "pattern" && (
          <p className="error">Please enter a valid email address.</p>
        )}
      </div>

      <div className="form__one-col">
        <label htmlFor="officeId">Office Location</label>
        <select
          className="office"
          name="officeId"
          ref={register({
            required: true,
            validate: (value) => value !== "default",
          })}
        >
          <option default disabled value="default">
            Select office location
          </option>
          {offices &&
            offices.map((office, index) => (
              <option name={office.name} value={office.id} key={index}>
                {office.name}
              </option>
            ))}
        </select>
        {errors.officeId && <p className="error">This is a required field.</p>}
      </div>

      <div className="form__one-col">
        <label htmlFor="endDate">Employment End Date</label>
        <Controller
          name="endDate"
          control={control}
          defaultValue={""}
          render={({ onChange, value }) => (
            <DatePicker
              selected={value}
              onChange={(e) => {
                onChange(e);
              }}
            />
          )}
          rules={{ required: true }}
        />
        {errors.endDate && <p className="error">This is a required field.</p>}
      </div>

      <div className="form__two-col form__two-col--centered">
        <input
          className="form__submit-button"
          type="submit"
          value={"Submit"}
        />
      </div>
    </form>
  );
};

export default Form;
