import http from "../http-common";

class SoftwareDataService {
	getAll() {
		return http.get("/softwares");
	}

	get(id) {
		return http.get(`/softwares/${id}`);
	}

	create(data) {
		return http.post("/softwares", data);
	}

	update(id, data) {
		return http.put(`/softwares/${id}`, data);
	}

	delete(id) {
		return http.delete(`/softwares/${id}`);
	}
}

export default new SoftwareDataService();