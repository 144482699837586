import React from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import { useStore } from "../../store/quoteStore";
import DragItem from "./DragItem";

const DraggingArea = () => {
  const history = useHistory();
  const { quotePages, changeQuotePages } = useStore();

  const onDragEnd = (res) => {
    const { destination, source } = res;

    // if no destination, don't do anything
    if (!destination) {
      return;
    }

    // if the position hasn't changed, don't do anything
    if (destination.index === source.index) {
      return;
    }

    // creates a new array, re-orders item, updates store
    const arrCopy = Array.from(quotePages);
    const [removed] = arrCopy.splice(source.index, 1);
    arrCopy.splice(destination.index, 0, removed);
    changeQuotePages(arrCopy);
  };

  const deletePage = (id) => {
    const quotePageIndex = quotePages.findIndex(
      (page) => page.id === parseInt(id)
    );
    const arrCopy = Array.from(quotePages);
    arrCopy.splice(quotePageIndex, 1);
    changeQuotePages(arrCopy);
  };

  const generateExcerpts = () => {
    history.push("/quotes/generated");
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="column">
        {(provided) => {
          return (
            <Column ref={provided.innerRef}>
              <h2 className="quotes__h2">Selected Excerpts</h2>
              {quotePages.map((item, i) => (
                <Draggable
                  key={item.id}
                  draggableId={item.id.toString()}
                  index={i}
                >
                  {(provided, snapshot) => (
                    <DragItem
                      index={i}
                      provided={provided}
                      isDragging={snapshot.isDragging}
                      title={item.title.rendered}
                      content={item.content.rendered}
                      category={item.categories[0]}
                      onClose={() => deletePage(item.id)}
                    />
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
              {quotePages.length > 0 ? (
                <button
                  className="quotes__add-page-button"
                  onClick={generateExcerpts}
                >
                  Generate Excerpts
                </button>
              ) : (
                <p>
                  Use the category and page selector on the left to add quote
                  excerpts to the list.
                </p>
              )}
            </Column>
          );
        }}
      </Droppable>
    </DragDropContext>
  );
};

export default DraggingArea;

const Column = styled.div`
  background: white;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  padding-bottom: 20px;
  box-shadow: 2px 2px 8px 2px rgba(0, 0, 0, 0.05);
`;
