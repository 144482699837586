import React from "react";

import { useLoginStore } from "../../store/loginStore";

import LoginScreen from "../global/LoginScreen";
import SectionChooser from "./SectionChooser";
import DraggingArea from "./DraggingArea";

const Form = () => {
  const { loggedIn } = useLoginStore();

  if (!loggedIn) {
    return (
      <main className="main-container">
        <LoginScreen />
      </main>
    );
  }

  return (
    <div className="quotes__container">
      <SectionChooser />
      <DraggingArea />
    </div>
  );
};

export default Form;
