import React from "react";
import { Link } from "react-router-dom";

import logo from "../../assets/madwell-logo.png";
import LoginStatus from "./LoginStatus.js";

const Header = ({ title }) => (
  <>
    <div className="header__container">
      <Link to="/">
        <img className="header__logo" src={logo} alt="Madwell logo" />
      </Link>
      <h1 className="header__title">{title}</h1>
    </div>
    <LoginStatus />
  </>
);

export default Header;
