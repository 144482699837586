import http from "../http-common";

class EmployeeDataService {
	getAll(token) {
		return http.get("/employees", {
			headers: {
				"Content-type": "application/json",
				"Authorization": `Bearer ${token}`
			}
		});
	}

	get(id, token) {
		return http.get(`/employees/${id}`, {
			headers: {
				"Content-type": "application/json",
				"Authorization": `Bearer ${token}`
			}
		});
	}

	create(data) {
		return http.post("/employees", data);
	}

	update(id, data) {
		return http.put(`/employees/${id}`, data);
	}

	delete(id) {
		return http.delete(`/employees/${id}`);
	}
}

export default new EmployeeDataService();